<template>
  <FormStructure
    :title="'Enter your email and we will send you an email with a password reset code.'"
  >
    <template v-slot:form-content>
      <div>
        <InputText :input-type="'text'" :label="'Email'" @input="setEmail" />
      </div>

      <div>
        <Button :btn-text="'Send'" @click="resetPass()" />
      </div>

      <BottomLink :link-route="'/login'" :text="'Back to login?'" />
    </template>
  </FormStructure>
</template>

<script>
import Button from "/src/components/auth/Button.vue";
import InputText from "/src/components/auth/InputText.vue";
import BottomLink from "/src/components/auth/BottomLink.vue";
import FormStructure from "/src/components/auth/FormStructure.vue";
import { mapActions } from "vuex";

export default {
  name: "ResetPassword",
  components: {
    Button,
    InputText,
    BottomLink,
    FormStructure,
  },
  data: () => {
    return {
      email: "",
      msg: "",
      hasError: false,
    };
  },
  methods: {
    setEmail(value) {
      this.email = value;
    },
    ...mapActions({ initialReset: "auth/initialReset" }),

    resetPass() {
      if (this.email != "") {
        this.initialReset(this.email);
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "A request to reset password has been sent to your email",
          uptime: 3000,
        });
      } else {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "All fields are required",
          uptime: 3000,
          hasError: true,
        });
      }
    },
  },
};
</script>

<style>
</style>